import { useCallback } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectedTheme } from '../../../../../../../../redux/features/themeSlice/themeSlice';
// Types
import { ISpaceInfo } from '@trii/types/dist/Spaces';
// Components/ui
import { List, Typography, Link, ListItemButton, ListItemText, Box } from '@mui/material';
import { Theme } from '@mui/system';
import { Settings } from '@mui/icons-material';

interface Props {
  space: ISpaceInfo;
}

const Space = ({ space }: Props) => {
  const spaceInfo = useSelector(selectSpaceInfo);
  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const openSelectedSpace = useCallback(
    (spaceItem) => () => {
      setLocalStorageSpaceInfo(spaceItem);
      location.replace('/')
    },
    []
  );

  const setLocalStorageSpaceInfo = (spaceInfo: ISpaceInfo) => {
    localStorage.setItem('spaceInfo', JSON.stringify(spaceInfo));
  };
  const navigateToSpaceSetting = (event, spaceItem) => {
    event.preventDefault();
    event.stopPropagation();
    setLocalStorageSpaceInfo(spaceItem);
    location.replace('/a/settings/workspace');
  };
  return (
    <List sx={{ paddingY: 0 }} key={space.id}>
      <Link
        className="header-position-relative"
        color={onSelectedTheme.palette.text.primary}
        sx={{
          "&:hover": {
            backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
            color: onSelectedTheme.palette.text.primary,
          },
          "& .Mui-selected": {
            backgroundColor: `${onSelectedTheme.palette.background.selectedItem} !important`,
          },
        }}
      >
        <ListItemButton
          // selected={isSpaceSelected}
          selected={spaceInfo?.id === space.id}
          onClick={openSelectedSpace(space)}
        >
          <ListItemText>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{
              '& svg': {
                width: '.9em',
                height: '.9em',
              }
            }}>

            <Typography
              variant={spaceInfo?.id === space.id ? "subtitle2" : "subtitle1"}
            >
              {space.name}
            </Typography>
            {space && (space.accessLevel === 10 || space.accessLevel === 11 || space.accessLevel === 20) && (
                <>
                <Settings
                  className="header-iconCornerRight"
                  sx={{ display: "none", position: 'initial'}}
                  onClick={(e) => navigateToSpaceSetting(e, space)}
                  />
                  </>
              )}
            </Box>
          </ListItemText>
        </ListItemButton>
      </Link>
    </List>
  );
};

export default Space;
