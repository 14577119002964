// Components/ui
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popover,
  Popper,
  PopperPlacementType,
  styled,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
// Components/icons
import { Apps } from '@mui/icons-material';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import AppsMenu from './components/AppsMenu/AppsMenu';
import { useContext, useEffect, useState } from 'react';
import { Theme } from '@mui/system';
import { useSelector } from 'react-redux';
import { selectedTheme } from '../../../../redux/features/themeSlice/themeSlice';
import { HeaderContext } from '../context/HeaderProvider';
import { getUser, selectUser } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';


type TooltipProps = {
  className?: string;
  title: string;
  placement: 'bottom';
};

const AppsDropdown = () => {
  const onSelectedTheme: Theme = useSelector(selectedTheme);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const { isPopoverOpen, setIsPopoverOpen } = useContext(HeaderContext);

  const TooltipPers = styled(({ className, title, placement }: TooltipProps) => (
    <Tooltip title={title} placement={placement} classes={{ popper: className }}>
      <Apps
        sx={{ color: onSelectedTheme.palette.color.icons }}
        className="cursor-pointer"
      />
    </Tooltip>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: onSelectedTheme.palette.background.tooltip,
    },
  }));

  // const handleClick = (newPlacement: PopperPlacementType) => (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setAnchorEl(event.currentTarget);
  //   setIsPopoverOpen((prev) => placement !== newPlacement || !prev);
  //   setPlacement(newPlacement);
  // };

  // const handleCloseSavePopup = () => {
  //   setIsPopoverOpen(false);
  // };

  // const onSelectedTheme = useSelector(selectedTheme);
  // const { t } = useTranslation();
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [placement, setPlacement] = useState<PopperPlacementType>();
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false); // Controla si el Popper está abierto
  const [isHovered, setIsHovered] = useState(false); // Controla si el cursor está dentro del Popper

  // const handleClick = (newPlacement: PopperPlacementType) => (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setAnchorEl(event.currentTarget);
  //   setIsPopoverOpen((prev) => placement !== newPlacement || !prev);
  //   setPlacement(newPlacement);
  // };

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      // Check if the popover is already open
      if (!isPopoverOpen) {
        setIsPopoverOpen(true);
        setPlacement(newPlacement);
      }
    };

  const handleCloseSavePopup = () => {
    setIsPopoverOpen(false);
  };
  const user = useSelector(selectUser);



  return (
    <Box>
      <IconButton
        onMouseEnter={handleClick('bottom-end')} // Abrir Popper al hacer hover
        onMouseLeave={() => setIsPopoverOpen(false)} // Cerrar Popper al salir
        onClick={handleClick('bottom-end')}
      >
        <TooltipPers title={t('dropdownApp.tooltip')} placement="bottom" />
      </IconButton>
      <Popper
        open={isPopoverOpen} // Mantener abierto si el Popper está abierto
        anchorEl={anchorEl}
        placement={placement}
        transition
        sx={{ zIndex: 99, marginTop: '10px !important' }}
        onMouseEnter={() => setIsPopoverOpen(true)} // Mantener abierto si el cursor está dentro
        onMouseLeave={() => setIsPopoverOpen(false)} // Cerrar Popper al salir
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCloseSavePopup}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ boxShadow: '0px 0px 2px 1px rgb(190 189 189)' }}>
                <AppsMenu user={user} />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

export default AppsDropdown;
