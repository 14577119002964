// React Router
import { useLocation } from 'react-router'
// Translations
import { useTranslation } from 'react-i18next'
// Components/ui
import { Box, IconButton, Typography } from "@mui/material"
// Icons
import { Close } from "@mui/icons-material"
// Components
import {
  KeyboardShortcuts,
} from "./components"
// Utils
import {
  contactsList,
  conversationList,
  globalList,
} from './utils/shortcutList'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/features/userSlice/userSlice'

interface Props {
  toggleModal: () => void
}

const KeyboardShortcutsModal = ({
  toggleModal
}: Props) => {
  const location = useLocation()
  const { t } = useTranslation()
  const userPermissions = useSelector(selectUser)

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: theme => theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        borderRadius: ".5rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "3rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: theme => theme.palette.text.primary,
            paddingLeft: "1rem",
          }}
        >
          {t('header.keyboardShortcuts.title')}
        </Typography>
        <IconButton
          onClick={toggleModal}
        >
          <Close
            sx={{
              color: theme => theme.palette.text.disabled
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 3rem)",
          overflowY: "scroll",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <KeyboardShortcuts
          shortcutList={globalList}
          title={'header.keyboardShortcuts.global.title'}
        />
        {
          userPermissions?.profile?.conversations?.enabled && (
            <KeyboardShortcuts
              shortcutList={conversationList}
              title={'header.keyboardShortcuts.conversations.title'}
            />
          )
        }
        {
          userPermissions?.profile?.contacts?.enabled && (
            <KeyboardShortcuts
              shortcutList={contactsList}
              title={'header.keyboardShortcuts.contacts.title'}
            />
          )
        }
      </Box>
    </Box>
  )
}

export default KeyboardShortcutsModal