import axios from 'axios';
// Utils
import getRequestConfig from 'redux/functions/getRequestConfig';
import { getToken, Messaging } from 'firebase/messaging';

async function logout(URL: string, jwtToken: string, firebaseToken: string) {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  return await axios.post(
    URL,
    {
      token: firebaseToken,
    },
    config
  );
}

async function getFirebaseToken(messaging: Messaging) {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        'BA90LIoCDtbVHh8MCIn9f1dAMZTZtxG-b_IJGJe9Gcl49ZcQFoaPGNrc5h0sJmVz4T1cRY7XPmEaPTnc6nyiF8w',
    });

    return token;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export default { logout, getFirebaseToken };
