// utils/updatePaths.ts
import { TFunction } from "i18next";

const updatePaths = (
  locationPathname: string,
  t: TFunction,
  setModulePath: (path: string) => void,
  setWindowPath: (path: string) => void
) => {
  let newLocation = locationPathname.replace(/^\//, "").toLowerCase();
  console.log("newLocation: " + newLocation)

  // Contacts
  if (newLocation.startsWith("a/contacts")) {
    setModulePath(t("header.contacts"));
    if (newLocation.startsWith("a/contacts/contacts")) {
      setWindowPath(t("header.contacts"));
    } else if (newLocation.startsWith("a/contacts/business")) {
      setWindowPath(t("header.business"));
    } else if (newLocation.startsWith("a/contacts/listmanagement")) {
      setWindowPath(t("header.listManagement"));
    } else if (newLocation.startsWith("a/contacts/segments")) {
      setWindowPath(t("header.segments"));
    } else if (newLocation.startsWith("a/contacts/duplicates")) {
      setWindowPath(t("header.duplicates"));
    } else if (newLocation.startsWith("a/contacts/importcontacts")) {
      setWindowPath(t("header.importContacts"));
    } else if (newLocation.startsWith("a/contacts/exportcontacts")) {
      setWindowPath(t("header.exportContacts"));
    } else if (newLocation.startsWith("a/contacts/settings")) {
      setWindowPath(t("header.settings"));
    } else {
      setWindowPath(t("Dashboard"));
    }
  }

  // Profile
  else if (newLocation.startsWith("a/settings/")) {
    setModulePath(t("header.profile"));
    if (newLocation.startsWith("a/settings/profile")) {
      setWindowPath(t("header.profile"));
    } else if (newLocation.startsWith("a/settings/dashboard")) {
      setWindowPath(t("Dashboard"));
    } else if (newLocation.startsWith("a/settings/workspace")) {
      setWindowPath(t("header.workspace"));
    } else if (newLocation.startsWith("a/settings/users")) {
      setWindowPath(t("header.users"));
    } else if (newLocation.startsWith("a/settings/security")) {
      setWindowPath(t("header.security"));
    } else if (newLocation.startsWith("a/settings/work-history")) {
      setWindowPath(t("header.workHistory"));
    } else {
      setWindowPath(t("Dashboard"));
    }
  }

  // Conversations
  else if (newLocation.startsWith("a/conversations")) {
    setModulePath(t("header.conversations"));
    if (newLocation.startsWith("a/conversations/system")) {
      setWindowPath(t("header.system"));
    } else if (newLocation.startsWith("a/conversations/socialwalls")) {
      setWindowPath(t("header.socialWalls"));
    } else if (newLocation.startsWith("a/conversations/myreports")) {
      setWindowPath(t("header.myReports"));
    } else if (newLocation.startsWith("a/conversations/conversations")) {
      setWindowPath(t("header.conversations"));
    } else if (newLocation.startsWith("a/conversations/dashboard")) {
      setWindowPath("Dashboard");
    } else {
      setWindowPath(t("Dashboard"));
    }
  }

  // Marketing
  else if (newLocation.startsWith("a/marketing")) {
    setModulePath(t("header.marketing"));
    if (newLocation.startsWith("a/marketing/dashboard")) {
      setWindowPath("Dashboard");
    } else if (newLocation.startsWith("a/marketing/system")) {
      setWindowPath(t("header.system"));
    } else if (newLocation.startsWith("a/marketing/marketing")) {
      setWindowPath(t("header.marketing"));
    } else if (newLocation.startsWith("a/marketing/reports")) {
      setWindowPath(t("header.reports"));
    } else {
      setWindowPath("Dashboard");
    }
  }

  // Calendar
  else if (newLocation.startsWith("a/calendar")) {
    setModulePath(t("header.calendar"));
    setWindowPath(t("header.calendar"));
  } else {
    setModulePath("");
    setWindowPath("");
  }
};

export default updatePaths;
